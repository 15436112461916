import { isBetween } from 'components/agenda/utils'
import { SimNaoEnum } from 'components/form/field/SimNaoRadioGroupField'
import { addDays, parseISO, toDate } from 'date-fns'
import {
  AcompanhamentoPreNatalQuery,
  AtendimentoProfissional,
  AtendimentoProfissionalPreNatal,
  Medicao,
  ResultadoExame,
} from 'graphql/types.generated'
import { isEmpty, orderBy } from 'lodash'
import { tipoEdemaRecord } from 'types/enums'
import { isUndefinedOrNull } from 'util/checks'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { OrigemDum } from 'view/atendimentos/detail/components/modals/medicoes/model-medicoes'
import { AtendimentoProfUltimaGestacao } from 'view/atendimentos/detail/components/modals/types/model-historicoPreNatal'
import {
  ResultadoExameEspecificoModel,
  ResultadosExamesModel,
} from 'view/atendimentos/detail/soap/objetivo/resultados-exames/model-resultadosExames'
import {
  MEDICOES_PRE_NATAL,
  PreNatalFormModel,
  QUANTIDADE_DIAS_FIM_PRIMEIRO_TRIMESTRE_GESTACAO,
} from 'view/atendimentos/detail/soap/pre-natal/model-preNatal'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

import { HistoricoPreNatalModel, MedicoesPreNatal } from '../../../../components/modals/types/model-historicoPreNatal'
import * as AcompanhamentoPreNatalUtils from './util-acompanhamentoPreNatal'

function compareAscDataExame(exameLeft: ResultadoExame, exameRight: ResultadoExame) {
  const dateLeft = toDate(exameLeft.dataRealizacao)
  const dateRight = toDate(exameRight.dataRealizacao)

  const diff = dateLeft.getTime() - dateRight.getTime()
  const idDiff = exameRight.id ? (exameLeft.id ? exameLeft.id > exameRight.id : true) : false

  if (diff < 0) {
    return -1
  } else if (diff > 0) {
    return 1
  } else {
    return idDiff ? 1 : -1
  }
}

export function getListaEcoOrdenadaDesc(
  resultadosExamesAnteriores: ResultadoExame[],
  dum: Date,
  resultadosExamesAtendimentoAtual?: ResultadoExameEspecificoModel[]
): [ResultadoExame[], boolean, boolean] {
  let listaEcoOrdenadaDesc: ResultadoExame[] = []
  resultadosExamesAnteriores?.forEach((exame) => {
    if (exame.especifico?.igSemanas || exame.especifico?.igDias || exame.especifico?.dpp) {
      listaEcoOrdenadaDesc.push(exame)
    }
  })
  let hasdppEcoAtendimento = false
  let hasExamesPreNatalAtendimento = false

  if (dum) {
    resultadosExamesAtendimentoAtual?.forEach((resultadoExame) => {
      if (resultadoExame?.resultado?.dpp || resultadoExame?.resultado?.idadeGestacional) {
        listaEcoOrdenadaDesc.push({
          dataRealizacao: resultadoExame.dataRealizado,
          especifico: {
            dpp: resultadoExame.resultado.dpp,
            igDias:
              resultadoExame.resultado.idadeGestacional?.dias && Number(resultadoExame.resultado.idadeGestacional.dias),
            igSemanas:
              resultadoExame.resultado.idadeGestacional?.semanas &&
              Number(resultadoExame.resultado.idadeGestacional.semanas),
          },
        } as ResultadoExame)
        hasdppEcoAtendimento = resultadoExame?.resultado?.dpp && true
        hasExamesPreNatalAtendimento = true
      }
    })
  }

  return [
    listaEcoOrdenadaDesc.sort((a, b) => compareAscDataExame(b, a)),
    hasExamesPreNatalAtendimento,
    hasdppEcoAtendimento,
  ]
}

function getInicioGestacaoEDataProvavelParto(dum: Date, listaEcoOrdenadaDesc: ResultadoExame[]): [Date, Date, boolean] {
  if (dum) {
    const dataLimite = addDays(dum, QUANTIDADE_DIAS_FIM_PRIMEIRO_TRIMESTRE_GESTACAO)
    for (let i = 0; i < listaEcoOrdenadaDesc?.length ?? 0; i++) {
      const dataRealizacaoExame = parseISO(listaEcoOrdenadaDesc[i].dataRealizacao)
      if (dum <= dataRealizacaoExame && dataRealizacaoExame <= dataLimite) {
        if (listaEcoOrdenadaDesc[i].especifico?.igSemanas) {
          const idadeGestacionalNaDataDaRealizacaoExame =
            listaEcoOrdenadaDesc[i].especifico.igSemanas * 7 + (listaEcoOrdenadaDesc[i].especifico.igDias ?? 0)
          const dataInicioGestacao = addDays(dataRealizacaoExame, -idadeGestacionalNaDataDaRealizacaoExame)
          const dataProvavelParto = listaEcoOrdenadaDesc[i].especifico.dpp
            ? parseISO(listaEcoOrdenadaDesc[i].especifico.dpp)
            : null
          return [dataInicioGestacao, dataProvavelParto, true]
        }
        return [dum, listaEcoOrdenadaDesc[i].especifico.dpp, false]
      }
    }
  }
  return [dum, null, false]
}

function getDumAtual(valoresComMedicaoAtual: AtendimentoProfissional[]): Date {
  for (let i = valoresComMedicaoAtual?.length - 1 ?? 0; i >= 0; i--) {
    const medicoes = valoresComMedicaoAtual[i]?.medicoes
    if (!isEmpty(medicoes) && medicoes[0].dum) {
      return parseISO(medicoes[0].dum)
    }
  }
}

function defineDumComStatus(valoresComMedicaoAtual: AtendimentoProfissional[], dumAtendimentoAtual?: string) {
  const dumPersistida = getDumAtual(valoresComMedicaoAtual)
  const dumFinal = dumAtendimentoAtual ? parseISO(dumAtendimentoAtual) : dumPersistida

  let origemDum: OrigemDum = OrigemDum.SEM_DUM
  if (dumAtendimentoAtual && dumPersistida) {
    origemDum = OrigemDum.AMBOS
  } else if (dumAtendimentoAtual) {
    origemDum = OrigemDum.ATENDIMENTO_ATUAL
  } else if (dumPersistida) {
    origemDum = OrigemDum.ATENDIMENTO_ANTERIOR
  }

  return { dum: dumFinal, origemDum }
}

type MedicaoAtendProfPreNatal = Pick<
  Medicao,
  'id' | 'dataMedicao' | 'valorBatimentoCardiacoFetal' | 'dum' | 'valorAlturaUterina' | 'valorPeso' | 'medicaoAnterior'
>

function convertMedicoesUltimaGestacao(
  indexMedicao: number,
  medicao: MedicaoAtendProfPreNatal,
  atendimentoProfissionalPreNatal: AtendimentoProfissionalPreNatal,
  dataMedicao: number | Date
): MedicoesPreNatal {
  return {
    numeroConsulta: indexMedicao + 1,
    dataDaMedicao: dateAsYyyyMmDd(dataMedicao),
    movimentacaoFetal: atendimentoProfissionalPreNatal?.movimentacaoFetal,
    edema: tipoEdemaRecord[atendimentoProfissionalPreNatal?.tipoEdema],
    batimentoCardiacoFetal: medicao?.valorBatimentoCardiacoFetal,
    alturaUterina: medicao?.valorAlturaUterina,
    peso: medicao?.valorPeso,
    isRegistradoAgora: false,
  }
}

function convertMedicoesAtendimentoAtual(
  dataAtendimento: number,
  preNatalAtendimentoAtual: PreNatalFormModel,
  pesoAtendimentoAtual: number,
  quantidadeMedicoesAtendimentosAnteriores: number
): MedicoesPreNatal {
  return {
    numeroConsulta: quantidadeMedicoesAtendimentosAnteriores + 1,
    dataDaMedicao: dateAsYyyyMmDd(dataAtendimento),
    movimentacaoFetal:
      preNatalAtendimentoAtual?.movimentacaoFetal && preNatalAtendimentoAtual.movimentacaoFetal === SimNaoEnum.SIM,
    edema: tipoEdemaRecord[preNatalAtendimentoAtual?.edema],
    batimentoCardiacoFetal: preNatalAtendimentoAtual?.batimentoCardiacoFetal?.toString(),
    alturaUterina: preNatalAtendimentoAtual?.alturaUterina?.toString(),
    peso: pesoAtendimentoAtual,
    isRegistradoAgora: true,
  }
}

function hasMedicoesPreNatalAtendimentoAtual(preNatalAtendimentoAtual: PreNatalFormModel): boolean {
  if (isUndefinedOrNull(preNatalAtendimentoAtual)) return false
  return MEDICOES_PRE_NATAL.some((item) => preNatalAtendimentoAtual[item])
}

function mustShowMedicoesAtendimentoAtual(
  isAtendimentoPreNatal: boolean,
  pesoAtendimentoAtual: number,
  preNatalAtendimentoAtual: PreNatalFormModel
): boolean {
  return (
    isAtendimentoPreNatal &&
    (!isUndefinedOrNull(pesoAtendimentoAtual) || hasMedicoesPreNatalAtendimentoAtual(preNatalAtendimentoAtual))
  )
}

export interface ConvertDadosPreNatalProps {
  data: AcompanhamentoPreNatalQuery
  preNatalAtendimentoAtual?: PreNatalFormModel
  resultadosExamesAtendimentoAtual?: ResultadosExamesModel
  pesoAtendimentoAtual?: number
  dumAtendimentoAtual?: string
  dataAtendimento?: Instant
  resultadosExamesAnteriores?: ResultadoExame[]
  isAtendimentoPreNatal?: boolean
}

export function convertDadosPreNatal({
  data,
  dataAtendimento,
  dumAtendimentoAtual,
  preNatalAtendimentoAtual,
  resultadosExamesAtendimentoAtual,
  pesoAtendimentoAtual,
  resultadosExamesAnteriores,
  isAtendimentoPreNatal = false,
}: ConvertDadosPreNatalProps): HistoricoPreNatalModel {
  let medicoes: MedicoesPreNatal[] = []

  const atendimentosProfissionaisUltimaGestacao = data?.atendimentosProfUltimaGestacao

  const { dum, origemDum } = defineDumComStatus(
    atendimentosProfissionaisUltimaGestacao as AtendimentoProfissional[],
    dumAtendimentoAtual
  )

  const ultimoDiaDoPrimeiroTrimestreGestacao = addDays(dum, QUANTIDADE_DIAS_FIM_PRIMEIRO_TRIMESTRE_GESTACAO)

  const resultadosExamesAnterioresPrimeiroTrimestre = resultadosExamesAnteriores?.filter((exame) =>
    isBetween(parseISO(exame.dataRealizacao), {
      start: dum,
      end: ultimoDiaDoPrimeiroTrimestreGestacao,
    })
  )
  const resultadosExamesAtendimentoAtualPrimeiroTrimestre = Object.values(
    resultadosExamesAtendimentoAtual?.resultadosSemSolicitacao ?? {}
  )?.filter((exame) =>
    isBetween(parseISO(exame.dataRealizado), {
      start: dum,
      end: ultimoDiaDoPrimeiroTrimestreGestacao,
    })
  )

  const [
    listaEcoOrdenadaDesc,
    hasExamesPreNatalAtendimento,
    hasdppEcoAtendimento,
  ] = AcompanhamentoPreNatalUtils.getListaEcoOrdenadaDesc(
    resultadosExamesAnterioresPrimeiroTrimestre,
    dum,
    resultadosExamesAtendimentoAtualPrimeiroTrimestre
  )

  const [dataInicioGestacao, dataProvavelParto, isEcografico] = getInicioGestacaoEDataProvavelParto(
    dum,
    listaEcoOrdenadaDesc
  )

  atendimentosProfissionaisUltimaGestacao?.forEach((atendProf, index) => {
    const medicao = !isEmpty(atendProf.medicoes) ? atendProf.medicoes[0] : null
    const dataMedicao = medicao?.dataMedicao ?? atendProf.iniciadoEm
    medicoes.push(convertMedicoesUltimaGestacao(index, medicao, atendProf.atendimentoProfissionalPreNatal, dataMedicao))
  })

  mustShowMedicoesAtendimentoAtual(isAtendimentoPreNatal, pesoAtendimentoAtual, preNatalAtendimentoAtual) &&
    medicoes.push(
      convertMedicoesAtendimentoAtual(dataAtendimento, preNatalAtendimentoAtual, pesoAtendimentoAtual, medicoes?.length)
    )

  medicoes = orderBy(medicoes, 'numeroConsulta', 'desc')
  return {
    medicoesPreNatal: medicoes,
    dataProvavelPartoEcografica: dataProvavelParto,
    dum,
    dataInicioGestacao,
    isEcografico,
    hasExamesPreNatalAtendimento,
    hasdppEcoAtendimento,
    origemDum,
  } as HistoricoPreNatalModel
}

export function convertToGrafico(valores: MedicoesPreNatal[]): HistoricoMedicaoModel[] {
  return valores.map((it) => ({
    dataMedicao: parseISO(it.dataDaMedicao),
    valorAlturaUterina: parseInt(it.alturaUterina, 10),
  }))
}

export function getAltoRisco(atendimentos?: AtendimentoProfUltimaGestacao[]) {
  return atendimentos?.some((atendProf) => atendProf.atendimentoProfissionalPreNatal?.preNatal?.altoRisco)
}
