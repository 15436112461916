/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, ExternalStyles, Grid, PagedTable, Tabs } from 'bold-ui'
import { TableColumnConfig } from 'bold-ui/lib/components/Table/DataTable/DataTable'
import { PageLoading } from 'components/loading'
import { TabLink } from 'components/route'
import { TableBox } from 'components/table/TableBox'
import { usePagination } from 'components/table/usePagination'
import { useFlags } from 'config/useFlagsContext'
import { useAcompanhamentoPreNatalQuery } from 'graphql/hooks.generated'
import { Fragment, useMemo } from 'react'
import { useRouteMatch } from 'react-router'
import {
  MedicoesPreNatal,
  MedicoesPreNatalAtendimentoModel,
} from 'view/atendimentos/detail/components/modals/types/model-historicoPreNatal'

import { convertDadosPreNatal } from '../../../../soap/aside/pre-natal/util/util-acompanhamentoPreNatal'
import { GraficoAlturaUterinaView } from '../graficos/altura-uterina/GraficoAlturaUterinaView'
import { HistoricoMedicoesPreNatalTabRoutes } from './HistoricoMedicoesPreNatalTabRoutes'
import { MedicaoPesoPreNatal } from './model-medicoesPreNatal'
import {
  renderDataMedicao,
  renderEdema,
  renderIdadeGestacional,
  renderLabelIG,
  renderMedicaoPreNatal,
  renderMovimentacaoFetal,
  renderNumeroConsulta,
} from './render-medicoesPreNatal'
import { getPesoAtendimentoAtual, getPesoPreGestacional, renderGanhoPeso } from './util-medicoesPreNatal'

interface HistoricoMedicoesPreNatalViewProps extends MedicoesPreNatalAtendimentoModel {
  prontuarioId: ID
  medicoesPeso: MedicaoPesoPreNatal[]
  isAtendimentoPreNatal?: boolean
}

export function HistoricoMedicoesPreNatalView(props: HistoricoMedicoesPreNatalViewProps) {
  const {
    prontuarioId,
    preNatalAtendimentoAtual,
    dataAtendimento,
    resultadosExamesAtendimentoAtual,
    dumAtendimentoAtual,
    medicoesPeso,
    isAtendimentoPreNatal = false,
  } = props

  const { data, loading } = useAcompanhamentoPreNatalQuery({
    variables: { input: prontuarioId },
  })

  const { GANHO_PESO_GESTACIONAL_ENABLED } = useFlags()

  const pesoAtendimentoAtual = getPesoAtendimentoAtual(medicoesPeso)

  const dataConverted = useMemo(
    () =>
      convertDadosPreNatal({
        data,
        preNatalAtendimentoAtual,
        resultadosExamesAtendimentoAtual,
        dumAtendimentoAtual,
        dataAtendimento,
        pesoAtendimentoAtual,
        isAtendimentoPreNatal,
      }),
    [
      data,
      preNatalAtendimentoAtual,
      resultadosExamesAtendimentoAtual,
      dumAtendimentoAtual,
      dataAtendimento,
      pesoAtendimentoAtual,
      isAtendimentoPreNatal,
    ]
  )

  const match = useRouteMatch()

  const { paginatedItems, tableProps: propsPagination } = usePagination({
    items: dataConverted?.medicoesPreNatal,
  })
  const { pageSize, ...propsPaginationToPagedTable } = propsPagination

  const tableProps = {
    ...propsPaginationToPagedTable,
    size: pageSize,
    rows: paginatedItems,
  }
  const pesoPreGestacional = getPesoPreGestacional(dataConverted.dum, medicoesPeso)

  return (
    <Grid>
      {loading && <PageLoading message='Carregando dados' />}
      {!loading && (
        <Fragment>
          <Cell size={12}>
            <TableBox>
              <PagedTable<MedicoesPreNatal>
                {...tableProps}
                columns={[
                  {
                    header: 'Consulta',
                    name: 'numeroConsulta',
                    render: (preNatal) => renderNumeroConsulta(preNatal.numeroConsulta),
                    style,
                  },
                  {
                    header: 'Data da medição',
                    name: 'dataMedicao',
                    render: (preNatal) => renderDataMedicao(preNatal.dataDaMedicao),
                    style,
                  },
                  {
                    header: renderLabelIG(dataConverted),
                    name: 'idadeGestacional',
                    render: (preNatal) =>
                      renderIdadeGestacional(dataConverted?.dataInicioGestacao, preNatal.dataDaMedicao),
                    style,
                  },
                  {
                    header: 'Edema',
                    name: 'edema',
                    render: (preNatal) => renderEdema(preNatal?.edema),
                    style,
                  },
                  {
                    header: 'Altura uterina (cm)',
                    name: 'alturaUterina',
                    render: (preNatal) => renderMedicaoPreNatal(preNatal.alturaUterina),
                    style,
                  },
                  getGanhoPesoColumn(GANHO_PESO_GESTACIONAL_ENABLED, pesoPreGestacional, style),
                  {
                    header: 'BCF (bpm)',
                    name: 'batimentoCardiacoFetal',
                    render: (preNatal) => renderMedicaoPreNatal(preNatal.batimentoCardiacoFetal),
                    style,
                  },
                  {
                    header: 'Movimentação fetal',
                    name: 'movimentacaoFetal',
                    render: (preNatal) =>
                      renderMovimentacaoFetal(preNatal?.movimentacaoFetal, preNatal.isRegistradoAgora),
                    style,
                  },
                ]}
              />
            </TableBox>
          </Cell>
          {dataConverted?.dataInicioGestacao && (
            <Cell size={12}>
              <GraficoAlturaUterinaView dataConvertedPreNatal={dataConverted} />
            </Cell>
          )}
        </Fragment>
      )}
      <Cell size={12}>
        <Tabs>
          {GANHO_PESO_GESTACIONAL_ENABLED && <TabLink to={`${match.url}/ganho-de-peso`}>Ganho de peso</TabLink>}
          <TabLink to={`${match.url}/altura-uterina`}>Altura uterina</TabLink>
        </Tabs>
      </Cell>
      <Cell size={12}>
        <HistoricoMedicoesPreNatalTabRoutes match={match} dataConvertedPreNatal={dataConverted} />
      </Cell>
    </Grid>
  )
}

function getGanhoPesoColumn(
  isGanhoPesoEnabled: boolean,
  pesoPreGestacional: number,
  style: ExternalStyles
): TableColumnConfig<MedicoesPreNatal> {
  return isGanhoPesoEnabled
    ? {
        header: 'Ganho de peso (kg)',
        name: 'ganhoPeso',
        render: (preNatal) => renderGanhoPeso(pesoPreGestacional, preNatal.peso),
        style,
      }
    : { name: 'ganhoPeso', render: () => null, style: { display: 'none' } }
}

const style = css`
  width: '5.5rem';
`
