import { Text } from 'bold-ui'
import { PecSwitch } from 'components/route'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'
import { Redirect, Route } from 'react-router'
import { match } from 'react-router'

import { HistoricoPreNatalModel } from '../../types/model-historicoPreNatal'
import { GraficoAlturaUterinaView } from '../graficos/altura-uterina/GraficoAlturaUterinaView'

interface HistoricoMedicoesPreNatalTabRoutesProps {
  match: match
  dataConvertedPreNatal: HistoricoPreNatalModel
}

export const HistoricoMedicoesPreNatalTabRoutes = (props: HistoricoMedicoesPreNatalTabRoutesProps) => {
  const { match, dataConvertedPreNatal } = props

  const { GANHO_PESO_GESTACIONAL_ENABLED } = useFlags()

  const initalUrl = GANHO_PESO_GESTACIONAL_ENABLED ? 'ganho-de-peso' : 'altura-uterina'

  return (
    <PecSwitch>
      <Redirect exact path={match.url} to={`${match.url}/${initalUrl}`} />
      {/* TODO (Eldorado #22596): Implementar exibição dos gráficos corretos */}
      {GANHO_PESO_GESTACIONAL_ENABLED && (
        <Route path={`${match.url}/ganho-de-peso`} render={() => <Text>Eis aqui um gráfico lindo e pomboso</Text>} />
      )}
      <Route
        path={`${match.url}/altura-uterina`}
        render={() => <GraficoAlturaUterinaView dataConvertedPreNatal={dataConvertedPreNatal} />}
      />
    </PecSwitch>
  )
}
