import moment from 'moment'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

import { MedicaoPesoPreNatal } from './pre-natal/model-medicoesPreNatal'

export function getMedicoesPeso(medicoes: HistoricoMedicaoModel[]): MedicaoPesoPreNatal[] {
  return medicoes
    .filter((medicao) => !!medicao.valorPeso)
    .map((medicao) => ({
      dataMedicao: medicao.dataMedicao as Instant,
      peso: medicao.valorPeso,
      tipoMedicao: medicao.tipoMedicao,
    }))
}

const format = (data) => moment(data).format('DD/MM/YY')

export function reduceMedicoesIguais(
  medicoes: ReadonlyArray<HistoricoMedicaoModel>,
  key: keyof HistoricoMedicaoModel
): HistoricoMedicaoModel[] {
  const mapMedicoesUnicas = medicoes?.reduce(
    (acc, curr) => (curr[key] ? acc.set(`${format(curr.dataMedicao)}|${curr[key]}`, curr) : acc),
    new Map<string, HistoricoMedicaoModel>()
  )

  return Array.from(mapMedicoesUnicas.values())
}
