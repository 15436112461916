import { isBefore } from 'date-fns'
import { maxBy } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'

import { MedicaoPesoPreNatal } from './model-medicoesPreNatal'
import { renderMedicaoPreNatal } from './render-medicoesPreNatal'

export function getPesoPreGestacional(dum: Date, medicoesPeso: MedicaoPesoPreNatal[]): number {
  if (isUndefinedOrNull(dum) || isUndefinedOrNull(medicoesPeso) || medicoesPeso.isEmpty()) return null

  const medicoesAnterioresDum = medicoesPeso.filter((medicaoPeso) => isBefore(medicaoPeso.dataMedicao, dum))
  const medicaoMaisRecenteAnteriorDum = maxBy(medicoesAnterioresDum, (medicao) => medicao.dataMedicao)

  if (isUndefinedOrNull(medicaoMaisRecenteAnteriorDum)) return null

  return medicaoMaisRecenteAnteriorDum.peso
}

export function computeGanhoPesoGestacional(pesoPreGestacional: number, pesoConsulta: number): number {
  if (isUndefinedOrNull(pesoPreGestacional) || isUndefinedOrNull(pesoConsulta)) return null
  return pesoConsulta - pesoPreGestacional
}

export function renderGanhoPeso(pesoPreGestacional: number, pesoConsulta: number): String {
  var ganhoPeso = computeGanhoPesoGestacional(pesoPreGestacional, pesoConsulta)
  return `${ganhoPeso < -0.005 ? '-' : ''}${renderMedicaoPreNatal(Math.abs(ganhoPeso))}`
}

export function getPesoAtendimentoAtual(medicoesPeso: MedicaoPesoPreNatal[]): number {
  return medicoesPeso.find((medicao) => medicao.tipoMedicao === 'atual')?.peso
}
